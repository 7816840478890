import {createSettingsParams, SettingsParamType} from '@wix/yoshi-flow-editor/tpa-settings'

export type ISettingsParams = {
  pageTitle: SettingsParamType.Text
  description: SettingsParamType.Text
  upcomingEventsTab: SettingsParamType.Text
  pastEventsTab: SettingsParamType.Text
  noUpcomingEvents: SettingsParamType.Text
  noUpcomingEventsLink: SettingsParamType.Text
  noUpcomingEventsLinkPageId: SettingsParamType.String
}

const settingsParams = createSettingsParams<ISettingsParams>({
  pageTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  description: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  upcomingEventsTab: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  pastEventsTab: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  noUpcomingEvents: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  noUpcomingEventsLink: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  noUpcomingEventsLinkPageId: {
    getDefaultValue: () => undefined,
  },
})

export default settingsParams
