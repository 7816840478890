import {getActiveElement} from '../../selectors/focus-handler'
import {connect} from '../runtime-context/connect'
import {DetailsPageAppProps} from '../../../components/details-page/Widget/components/app/interfaces'
import {MemberAppProps} from '../../../components/members-page/interface'
import {FocusHandlerRuntimeProps} from './interfaces'
import {FocusHandler as Presentation} from './focus-handler'

const mapRuntime = ({
  state,
  actions: {setActiveElement},
}: DetailsPageAppProps | MemberAppProps): FocusHandlerRuntimeProps => ({
  activeElement: getActiveElement(state.focusHandler),
  setActiveElement,
})

export const FocusHandler = connect<any, FocusHandlerRuntimeProps>(mapRuntime)(Presentation)
