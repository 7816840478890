import React from 'react'
import {TPAComponentsProvider} from 'wix-ui-tpa/TPAComponentsConfig'
import {useWixSdk, useEnvironment, useExperiments} from '@wix/yoshi-flow-editor'
import {ErrorPage} from '../../../../../commons/components/error-page'
import {RuntimeContext} from '../../../../../commons/components/runtime-context/runtime-context'
import {DatesProvider} from '../../../../../commons/hooks/dates'
import {MemberAppProps} from '../../../interface'
import {shouldLoadMore} from '../../selectors/events'
import {AppContainer} from '../app-container'
import {usePrevious} from '../../../../../commons/hooks/use-previous'

const SCROLL_CUTOFF = 0.95

export const App = (props: MemberAppProps) => {
  const {state, isRTL, host, actions} = props
  const {Wix} = useWixSdk()
  const {isMobile, isPreview} = useEnvironment()
  const {experiments} = useExperiments()
  const prevFormFactor = usePrevious(host.formFactor)
  const eventsEditorOOIEnabled = experiments.enabled('specs.events.ui.EventsEditorOOI')

  React.useEffect(() => {
    host.registerToScroll(({progressY}) => {
      if (progressY > SCROLL_CUTOFF && shouldLoadMore(state)) {
        actions.getEvents(state.tab)
      }
    })

    if (!eventsEditorOOIEnabled && Wix) {
      Wix.addEventListener(Wix.Events.SETTINGS_UPDATED, updateSettings)
      Wix.addEventListener(Wix.Events.EDIT_MODE_CHANGE, resetView)
      Wix.addEventListener(Wix.Events.SITE_PUBLISHED, onSitePublished)
    }

    return () => {
      if (!eventsEditorOOIEnabled && Wix) {
        // @ts-expect-error
        Wix.removeEventListener(Wix.Events.SETTINGS_UPDATED, updateSettings)
        // @ts-expect-error
        Wix.removeEventListener(Wix.Events.EDIT_MODE_CHANGE, resetView)
      }
    }
  }, [])

  React.useEffect(() => {
    if (actions && host && state) {
      if (isPreview && host.formFactor !== prevFormFactor) {
        actions.setFormFactor(host.formFactor)
      }
    }
  }, [host.formFactor])

  const updateSettings = action => actions.updateSettings(action)

  const resetView = () => actions.resetToLiveView()

  const onSitePublished = () => actions.updateComponent()

  if (!state) {
    return <ErrorPage />
  }

  return (
    <RuntimeContext.Provider value={props}>
      <TPAComponentsProvider value={{mobile: isMobile}}>
        <DatesProvider dates={state.dates}>
          <AppContainer isRTL={isRTL} />
        </DatesProvider>
      </TPAComponentsProvider>
    </RuntimeContext.Provider>
  )
}
