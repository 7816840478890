import {isMobile, isSite} from '../../../../../commons/selectors/environment'
import {getNoUpcomingEventsUrl} from '../../selectors/navigation'
import {MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {getUserText} from '../../selectors/component'
import {MembersTexts} from '../../../../../commons/enums'
import {NoEventsRuntimeProps} from './interfaces'
import {NoEvents as Presentation} from './no-events'

const mapRuntime = ({
  state,
  actions: {seeOtherEvents, navigateToNoUpcomingEventsUrl},
}: MemberPageContext): NoEventsRuntimeProps => ({
  navigate: !isSite(state) ? navigateToNoUpcomingEventsUrl : null,
  url: isSite(state) ? getNoUpcomingEventsUrl(state) : null,
  cta: getUserText(state, MembersTexts.noUpcomingEventsLink),
  mobile: isMobile(state),
  seeOtherEvents,
})

export const NoEvents = connect<{}, NoEventsRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
